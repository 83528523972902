import React, { Fragment } from "react";
import { reduce, drop, slice, findIndex, flatten, some, last } from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Figure from "react-bootstrap/Figure";
import usePeople from "../people";
import { GetDataIndices, GetRowData } from "../../util/data";
import { useRoleDescriptions } from "../roleDescriptions";
import { PersonAvatar, RoleContainer, RoleImage } from "../../components/roles/roleImages";
import styled from "styled-components";

function RolesSummaryRenderer({ data, className, project }) {
  const [people] = usePeople();
  const { activePeople } = combineRolesWithPeople(project, data.roles, people);

  return (
    <Row className="justify-content-md-center">
      {activePeople.map((person) => (
        <Col className="role-col-md mb-4" key={person.person}>
          <PersonAvatar person={person} showBadges />
        </Col>
      ))}
    </Row>
  );
}

function RolesRenderer({ data, project }) {
  const [people] = usePeople();
  const { allPeople } = combineRolesWithPeople(project, data.roles, people);
  const roleDescriptions = useRoleDescriptions();

  const roleRows = reduce(
    roleDescriptions,
    (prev, role) => {
      var rolePeople = allPeople.filter((person) => person.roles.includes(role.name));

      if (rolePeople.length === 0) return prev;
      role = { ...role, people: rolePeople };

      if (prev.length === 0 || (role.isLead && role.team !== last(last(prev)).team)) {
        return [...prev, [role]];
      }

      last(prev).push(role);
      return prev;
    },
    []
  );

  const unAssignedPeople = allPeople.filter((person) => person.roles.length === 0);

  return (
    <Fragment>
      {roleRows.map((roles, index) => (
        <Row className="justify-content-md-center" key={index}>
          {roles.map((role) => (
            <Col sm="auto" key={role.name}>
              <Figure>
                <FlexContainer>
                  <RoleImage roleInfo={role} size="sm" />
                  {role.people.map((p) => (
                    <LayeredPersonAvatar person={p} key={p.person} size="sm" />
                  ))}
                </FlexContainer>
                <Figure.Caption className="text-center">
                  <RoleContainer roleInfo={role}>{role.name}</RoleContainer>
                </Figure.Caption>
              </Figure>
            </Col>
          ))}
        </Row>
      ))}
      {unAssignedPeople.length > 0 && (
        <Row className="justify-content-md-center">
          <Col sm="auto">
            <Figure>
              <FlexContainer>
                {unAssignedPeople.map((p) => (
                  <LayeredPersonAvatar person={p} key={p.person} size="sm" />
                ))}
              </FlexContainer>
              <Figure.Caption className="text-center">Unassigned</Figure.Caption>
            </Figure>
          </Col>
        </Row>
      )}
    </Fragment>
  );
}

const FlexContainer = styled.div`
  display: flex;
`;

const LayeredPersonAvatar = styled(PersonAvatar)`
  margin-left: -1.5em;
  &:first-child {
    margin-left: 0;
  }
`;

function isStringDateInPast(dateString) {
  let [day, month] = dateString.split("/");
  day = +day;
  month = +month;
  const today = new Date();
  const [todayDay, todayMonth] = [today.getDate(), today.getMonth() + 1];
  return month < todayMonth || (month === todayMonth && day < todayDay);
}

function getDaysOnProject(peoplePerson, project) {
  return flatten(peoplePerson.weeks)
    .filter((day) => day.project === project.code)
    .filter(({ date }) => !isStringDateInPast(date));
}

function combineRolesWithPeople(project, rolesData, people) {
  let activePeople = [];
  let inactivePeople = [];
  let allPeople = [];
  const today = new Date();
  const formattedToday = `${today.getDate()}/${today.getMonth() + 1}`;

  rolesData.forEach((rolePerson) => {
    const peoplePerson = people.find((p) => p.name === rolePerson.person);
    allPeople.push(rolePerson);

    if (peoplePerson) {
      const daysOnProject = getDaysOnProject(peoplePerson, project);
      if (daysOnProject.length > 0) {
        activePeople.push(rolePerson);
        rolePerson.isCore = true;
        rolePerson.isOnThisToday = daysOnProject.find((day) => day.date === formattedToday) != null;
        rolePerson.nextOnProject = daysOnProject[0];
        return;
      }
    }
    rolePerson.isCore = false;
    rolePerson.isOnThisToday = false;
    rolePerson.nextOnProject = undefined;
    inactivePeople.push(rolePerson);
  });

  //add any people who are assigned to the project, but which haven't been given roles
  people.forEach((person) => {
    if (some(rolesData, (rolesPerson) => rolesPerson.person === person.name)) {
      return;
    }

    const daysOnProject = getDaysOnProject(person, project);
    if (daysOnProject.length === 0) return;

    let newPerson = {
      person: person.name,
      roles: [],
      isCore: true,
      nextOnProject: daysOnProject.length > 0 ? daysOnProject[0] : undefined,
      isOnThisToday: daysOnProject.find((day) => day.date === formattedToday) != null,
    };

    activePeople.push(newPerson);
    allPeople.push(newPerson);
  });

  return {
    allPeople,
    activePeople,
    inactivePeople,
  };
}

function buildRolesData(tabData) {
  const ROLES_HEADINGS = {
    Project: "Project",
    Person: "Person",
    Roles: "Roles",
  };

  let indices = GetDataIndices(ROLES_HEADINGS, tabData);

  return reduce(
    drop(tabData),
    (curr, d) => {
      let rowData = GetRowData(indices, d);
      let index = findIndex(curr, (c) => c.project === rowData.Project);

      if (rowData.Project !== "" && index < 0) {
        curr.push({
          project: rowData.Project,
          roles: [],
        });
      }
      index = findIndex(curr, (c) => c.project === rowData.Project);
      if (index >= 0) {
        curr[index].roles.push({
          person: rowData.Person,
          roles: slice(d, indices[ROLES_HEADINGS.Roles]),
        });
      }
      return curr;
    },
    []
  );
}

const rolesTab = {
  id: "roles",
  buildData: buildRolesData,
  component: RolesRenderer,
  summaryPriority: 1,
  summary: RolesSummaryRenderer,
};

export default rolesTab;
