import { map } from "lodash";
import React, { useState } from "react";
import { Figure, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRoleDescriptions } from "../../hooks/roleDescriptions";
import { slugs } from "../../util/slugs";

const getRoleUrl = ({ pathname, search }, role) =>
  `${pathname}${pathname.endsWith("/") ? "" : "/"}${slugs.role}/${role.replace(" ", "-")}${search}`;

const getPersonUrl = ({ pathname, search }, person) =>
  `${pathname}${pathname.endsWith("/") ? "" : "/"}${slugs.person}/${person.replace(" ", "-")}${search}`;

function RoleContainer({ roleInfo, size, children }) {
  const location = useLocation();

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id={`tooltip-role`}>
          {roleInfo.name} - {roleInfo.shortDesc}
        </Tooltip>
      }
    >
      <SizedContainer size={size}>
        <Link to={getRoleUrl(location, roleInfo.name)}>{children}</Link>
      </SizedContainer>
    </OverlayTrigger>
  );
}

function RoleImage({ roleInfo, size, className, plain }) {
  if (plain) {
    return (
      <SizedContainer size={size}>
        <Figure.Image key={roleInfo.name} src={getRoleImageUrl(roleInfo)} />
      </SizedContainer>
    );
  }

  return (
    <RoleContainer roleInfo={roleInfo} size={size} className={className}>
      <Figure.Image key={roleInfo.name} src={getRoleImageUrl(roleInfo)} />
    </RoleContainer>
  );
}

function RoleBadges({ roles }) {
  const roleInfos = useRoleDescriptions();

  return (
    <div className="role-badge-container">
      <ul className="role-badge-list">
        {roles.map((role) => (
          <li className="role-badge" key={role}>
            {roleInfos[role] && <RoleImage roleInfo={roleInfos[role]} />}
          </li>
        ))}
      </ul>
    </div>
  );
}

function PersonAvatar({
  className,
  person: { person, isCore, nextOnProject, isOnThisToday, roles },
  size,
  showBadges,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();

  return (
    <AvatarContainer
      className={className}
      size={size || "md"}
      isBordered={isOnThisToday}
      transparent={!isCore}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={isHovered ? { zIndex: 1000 } : {}}
    >
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-person`}>
            {person}
            <br />
            {roles.length > 0 && (
              <>
                {roles.join(", ")}
                <br />
              </>
            )}
            {isOnThisToday ? (
              <>
                On this project today
                <br />
              </>
            ) : isCore ? (
              <>
                Next on project on {nextOnProject.date}
                <br />
              </>
            ) : (
              <>
                Not assigned for 4 weeks
                <br />
              </>
            )}
          </Tooltip>
        }
      >
        <ImageContainer>
          <Link to={getPersonUrl(location, person)}>
            {HasImageForPerson(person) ? (
              <Figure.Image
                src={`/images/${isHovered ? "Poke " : ""}${person}.${PersonImages[person]}`}
                className="mb-0"
                fluid
                roundedCircle
              />
            ) : (
              <PersonName className="text-center">{`${isHovered ? "Poké-" : ""}${person}`}</PersonName>
            )}
          </Link>
        </ImageContainer>
      </OverlayTrigger>
      {showBadges && <RoleBadges size={size || "md"} roles={roles} />}
    </AvatarContainer>
  );
}

const PersonImage = ({ person, size, className }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <AvatarContainer
      className={className}
      size={size || "md"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ImageContainer onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {HasImageForPerson(person) ? (
          <Figure.Image
            src={`/images/${isHovered ? "Poke " : ""}${person}.${PersonImages[person]}`}
            className="mb-0"
            fluid
            roundedCircle
          />
        ) : (
          <PersonName className="text-center">{`${isHovered ? "Poké-" : ""}${person}`}</PersonName>
        )}
      </ImageContainer>
    </AvatarContainer>
  );
};

const Sizes = {
  xs: "2em",
  sm: "5em",
  md: "7em",
  lg: "9em",
};

const PersonName = styled.h5`
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
  color: var(--body-color);
`;

const ImageContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  border-radius: 50%;
`;

const SizedContainer = styled.div`
  display: inline-block;
  position: relative;
  width: ${(props) => Sizes[props.size] || "100%"};
  height: ${(props) => Sizes[props.size] || "100%"};
`;

const AvatarContainer = styled(SizedContainer)`
  border-color: var(--project-graph-colour);
  border-width: ${({ isBordered }) => (isBordered ? "0.3em" : "0.15em")};
  border-style: ${({ transparent }) => (transparent ? "dashed" : "solid")};
  border-radius: 50%;
  ${ImageContainer} img, ${ImageContainer} ${PersonName} {
    opacity: ${({ transparent }) => (transparent ? "70%" : "100%")};
  }

  ${({ transparent }) =>
    transparent
      ? `
    ${ImageContainer} {
      background-color: var(--project-colour);
      :not(:has(${PersonName})) > a {
        background-color: var(--card-overlay);
        width: 100%;
        height: 100%;
        display: inline-block;
      }
    }
  
  `
      : ""}
`;

const HasImageForPerson = (name) => {
  return PersonImages.hasOwnProperty(name);
};

const LEAD = "_Lead";
export const roleImages = {
  ui: "Electric_UI",
  dev: "Fire_Development",
  art: "Grass_art",
  pm: "Psychic_Production",
  design: "Rock_design",
  qa: "Water_QA",
};

function getRoleImageUrl(roleInfo) {
  return `/badges/${roleImages[roleInfo.team]}${roleInfo.isLead ? LEAD : ""}_128.png`;
}

const PersonImages = {
  Adam: "jpg",
  Casey: "jpg",
  Clarisse: "jpg",
  Dean: "jpg",
  Gabriella: "png",
  "James L": "jpg",
  Jen: "jpg",
  Jessy: "jpg",
  Nathalie: "jpg",
  Paul: "jpg",
  Ryan: "jpg",
  Will: "jpg",
  Nico: "jpg",
  Elric: "jpg",
  Jacob: "jpg",
  Connor: "jpg",
  Mike: "jpg",
  Sebastian: "jpg",
  Ailin: "jpg",
  Andrew: "png",
  Pete: "jpg",
  Maeskye: "jpg",
  Megha: "jpg",
  "James B": "png",
};

function PersonImagePreloader() {
  return (
    <>
      {map(PersonImages, (ext, person) => (
        <link key={person} rel="preload" href={`/images/Poke ${person}.${ext}`} as="image" />
      ))}
    </>
  );
}

export { PersonImage, PersonAvatar, PersonImagePreloader, RoleImage, RoleContainer };
