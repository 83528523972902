import React, { useState } from "react";
import {
  head,
  reduce,
  drop,
  findIndex,
  values,
  has,
  zipObject,
  mapValues,
  toNumber,
  keys,
  sumBy,
  max,
  some,
} from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { getWeeksBetween, getWeekTime } from "./time";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import BulletGraph from "./bullet";
import moment from "moment";
import { Form } from "react-bootstrap";

const StyledDropdownButton = styled(DropdownButton)`
  display: inline-block;

  button {
    padding: 0;
  }
`;

const LabelBudget = "Current Estimate w/o Contingency - ";
const LabelSpent = "Toggl hours logged so far - ";
const LabelContingency = "with Contingency* - ";
const LabelEstimatedTickets = "Estimated Tickets - ";
const LabelCompletedTickets = "Completed Tickets - ";
const LabelsCategories = {
  Total: "Total",
  Design: "design",
  UI: "ui",
  Development: "dev",
  Art: "art",
  Sound: "sound",
  QA: "qa",
  PM: "pm",
};
const Categories = keys(LabelsCategories);

function HealthRenderer({ data, className, project }) {
  const [stageIndex, setStageIndex] = useState(
    Math.max(
      findIndex(
        data.stages,
        (s) =>
          s.name !== "Total" &&
          s.time &&
          s.time.start &&
          s.time.end &&
          getWeeksBetween(s.time.start, s.time.today) >= 0 &&
          getWeeksBetween(s.time.today, s.time.end) > 0
      ),
      0
    )
  );
  const [category, setCategory] = useState("Total");
  const [showPercentages, setShowPercentages] = useState(false);

  const onSwitchPercentages = () => setShowPercentages(!showPercentages);

  return (
    <div className={classnames(className, "mb-2")}>
      <h4 className="text-center mb-0">
        {stageIndex > 0 && (
          <FontAwesomeIcon className="float-left" icon={faChevronLeft} onClick={() => setStageIndex(stageIndex - 1)} />
        )}
        <StyledDropdownButton size="lg" variant="link" title={data.stages[stageIndex].name}>
          {data.stages.map((stage, index) => (
            <Dropdown.Item key={stage.code} onClick={() => setStageIndex(index)}>
              {stage.name}
            </Dropdown.Item>
          ))}
        </StyledDropdownButton>
        {stageIndex < data.stages.length - 1 && (
          <FontAwesomeIcon
            className="float-right"
            icon={faChevronRight}
            onClick={() => setStageIndex(stageIndex + 1)}
          />
        )}
      </h4>
      <div className="text-center">
        <StyledDropdownButton variant="link" title={category}>
          {Categories.map((cat) => (
            <Dropdown.Item key={cat} onClick={() => setCategory(cat)}>
              {cat}
            </Dropdown.Item>
          ))}
        </StyledDropdownButton>

        <SwitchContainer>
          <Form onClick={onSwitchPercentages}>
            <StyledSwitch checked={showPercentages} onChange={onSwitchPercentages} label="%ages" />
          </Form>
        </SwitchContainer>
      </div>
      <StageHealthRenderer
        isVertical={true}
        data={data.stages[stageIndex]}
        category={category}
        usePercentages={showPercentages}
      />
    </div>
  );
}

const SwitchContainer = styled.div`
  float: right;
  margin-left: -100%;
  padding-right: 0.5em;
  font-size: 0.8em;
`;

const StyledSwitch = styled(Form.Switch)`
  .custom-control-label {
    padding-top: 0.125rem;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--project-text-colour);
    background-color: var(--project-colour);
  }
`;

function HealthSummaryRenderer({ data, className }) {
  const stageIndex = Math.max(
    findIndex(
      data.stages,
      (s) =>
        s.name !== "Total" &&
        s.time &&
        s.time.start &&
        s.time.end &&
        getWeeksBetween(s.time.start, s.time.today) >= 0 &&
        getWeeksBetween(s.time.today, s.time.end) > 0
    ),
    0
  );
  return (
    <div className={classnames(className, "mb-2")}>
      <h4 className="text-center mb-0">{data.stages[stageIndex].name}</h4>
      <StageHealthSummaryContainer className="mb-4">
        <BulletGraph data={data.stages[stageIndex]} category="Total" />
      </StageHealthSummaryContainer>
    </div>
  );
}

const StageHealthColourSetup = styled.div`
  --time-colour: var(--project-graph-colour);
  --budget-colour: var(--project-graph-colour);
  --marker-colour: var(--project-colour);
  --untagged-colour: var(--danger);
  --unassigned-colour: var(--project-graph-colour);
  color: var(--project-graph-colour) !important;
`;

const StageHealthSummaryContainer = styled(StageHealthColourSetup)`
  text-align: center;
`;

const StageHealthContainer = styled(StageHealthColourSetup)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
`;

const LargeStageHealthContainer = styled(StageHealthContainer)``;

function StageHealthRenderer({ data, category, isVertical, usePercentages }) {
  const location = useLocation();
  const isHome = location.pathname === "/";

  const HealthComponent = isHome ? StageHealthContainer : LargeStageHealthContainer;

  return (
    <HealthComponent className="mb-4">
      <BulletGraph data={data} category={category} isVertical={isVertical} usePercentages={usePercentages} />
    </HealthComponent>
  );
}

function buildHealthData(tabData) {
  const headers = head(tabData);

  const allProjects = reduce(
    drop(tabData),
    (projects, d) => {
      if (d.length === 0) return projects;
      const projectCode = d[0];
      if (!has(projects, projectCode)) {
        projects[projectCode] = {
          project: projectCode,
          stages: [],
        };
      }

      const stageData = zipObject(headers, d);

      projects[projectCode].stages.push({
        code: d[1],
        name: d[2],
        isFixed: d[3] === "TRUE",
        time: {
          moments: {
            start: stageData["Start Date"] ? moment(stageData["Start Date"], "DD/MM/YYYY") : undefined,
            end: stageData["End Date"] ? moment(stageData["End Date"], "DD/MM/YYYY") : undefined,
          },
          start: getWeekTime(stageData["Start Date"]),
          end: getWeekTime(stageData["End Date"]),
          lastUpdated: getWeekTime(stageData["Last updated"], -1),
          today: getWeekTime(),
          totalWeeks: toNumber(stageData["Time (Weeks)"]),
          weeksSoFar: toNumber(stageData["Toggl hours logged so far - Time (Full Weeks)"]),
          activeNow: stageData["Active this week"] === "TRUE",
        },
        budget: mapValues(LabelsCategories, (cat) => toNumber(stageData[LabelBudget + cat])),
        contingency: mapValues(
          LabelsCategories,
          (cat) => toNumber(stageData[LabelContingency + cat]) - toNumber(stageData[LabelBudget + cat])
        ),
        estimated: mapValues(LabelsCategories, (cat) => toNumber(stageData[LabelEstimatedTickets + cat])),
        completed: mapValues(LabelsCategories, (cat) => toNumber(stageData[LabelCompletedTickets + cat])),
        spent: mapValues(LabelsCategories, (cat) => toNumber(stageData[LabelSpent + cat])),
        untagged: toNumber(stageData["Toggl Tracking Errors - Untagged"]),
        unassigned: toNumber(stageData["Toggl Tracking Errors - Unassigned to Stage"]),
      });
      return projects;
    },
    {}
  );

  const ret = values(
    mapValues(allProjects, (project) => {
      if (project.stages.length > 1) {
        project.stages = [
          {
            code: project.project,
            name: "Total",
            isFixed: true,
            time: {
              start: getWeekTime(
                moment.min(project.stages.map((stage) => stage.time.moments.start).filter((d) => d !== undefined))
              ),
              end: getWeekTime(
                moment.max(project.stages.map((stage) => stage.time.moments.end).filter((d) => d !== undefined))
              ),
              lastUpdated: project.stages[0].time.lastUpdated,
              today: getWeekTime(),
              totalWeeks: sumBy(project.stages, (stage) => stage.time.totalWeeks),
              weeksSoFar: sumBy(project.stages, (stage) => stage.time.weeksSoFar),
              activeNow: some(project.stages, (stage) => stage.time.activeNow),
            },
            budget: mapValues(LabelsCategories, (_i, cat) => sumBy(project.stages, (stage) => stage.budget[cat] || 0)),
            contingency: mapValues(LabelsCategories, (_i, cat) =>
              sumBy(project.stages, (stage) => stage.contingency[cat] || 0)
            ),
            estimated: mapValues(LabelsCategories, (_i, cat) =>
              sumBy(project.stages, (stage) => stage.estimated[cat] || 0)
            ),
            completed: mapValues(LabelsCategories, (_i, cat) =>
              sumBy(project.stages, (stage) => stage.completed[cat] || 0)
            ),
            spent: mapValues(LabelsCategories, (_i, cat) => sumBy(project.stages, (stage) => stage.spent[cat] || 0)),
            untagged: sumBy(project.stages, (stage) => stage.untagged || 0),
            unassigned: max(project.stages.map((stage) => stage.unassigned || 0)),
          },
          ...project.stages,
        ];
      }

      return project;
    })
  );
  return ret;
}

const healthTab = {
  id: "health",
  buildData: buildHealthData,
  component: HealthRenderer,
  summary: HealthSummaryRenderer,
  summaryPriority: 2,
};

export default healthTab;
