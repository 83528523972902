import React from "react";
import { Col, Spinner } from "react-bootstrap";
import ProjectCard from "./project";
import { useProjectTabs } from "hooks/useTabs";
import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";

function ProjectColumn({ project, personName, activeTab }) {
  const [tabs] = useProjectTabs(project);
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = search ? qs.parse(search.substr(1)) : {};

  function onTabSelected(newTab) {
    searchParams[`${project.code}-${project.milestone}`] = newTab;
    history.push({
      search: `?${qs.stringify(searchParams)}`,
    });
  }

  if (!project) {
    return <Spinner animation="border" />;
  }

  let order = 3;
  if (personName) {
    const rolesTab = tabs.find((t) => t.type === "roles");
    if (rolesTab) {
      const role = rolesTab.data.roles.find((r) => r.person === personName);
      if (role) {
        order = role.isCore ? 1 : 2;
      }
    }
  }

  return (
    <Col xs={{ span: 12, order }} lg="6" xl="4">
      <ProjectCard project={project} activeTab={activeTab} onTabSelected={onTabSelected} />
    </Col>
  );
}

export default ProjectColumn;
