import moment from "moment";

export function getWeekTime(stringTime, deltaDays = 0) {
  let mTime = stringTime ? moment(stringTime, "DD/MM/YYYY") : moment();
  // mTime.add(deltaDays, "days");
  let incrementWeek = mTime.day() > 5;
  return {
    startOfWeekLabel: mTime.clone().startOf("week").add(1, "day").format("Do MMM"),
    label: mTime.format("Do MMM"),
    year: mTime.year(),
    week: mTime.week() + (incrementWeek ? 1 : 0),
    day: incrementWeek ? 0 : mTime.day() - 1,
  };
}

export function getWeekNumber(time, refYear) {
  return (time.year - refYear) * 52 + time.week + time.day / 5;
}

export function getWeeksBetween(time1, time2) {
  const refYear = Math.min(time1.year, time2.year);
  const week1 = getWeekNumber(time1, refYear);
  const week2 = getWeekNumber(time2, refYear);
  return week2 - week1;
}

export function getFlatWeekNumber(time, refYear) {
  return (time.year - refYear) * 52 + time.week;
}

export function getFlatWeeksBetween(time1, time2) {
  const refYear = Math.min(time1.year, time2.year);
  const week1 = getFlatWeekNumber(time1, refYear);
  const week2 = getFlatWeekNumber(time2, refYear);
  return week2 - week1;
}
