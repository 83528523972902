import { reduce, drop, toLower } from "lodash";
import { GetDataIndices, GetRowData } from "../util/data";
import useGSheet from "./gsheets";

const roleDescriptionSpreadsheetId = "1Uk6Q74QhwR-65n7IO3qFVaZxUlCMCqfvFO5_vWhgpXQ";
const roleDescriptionSheet = "CC Role Descriptions";

function useRoleDescriptions() {
  const [roleDescriptionData] = useGSheet(roleDescriptionSpreadsheetId, roleDescriptionSheet);

  const HEADINGS = {
    Role: "Role",
    Team: "Team",
    IsLead: "Lead?",
    ShortDesc: "Short Description",
    LongDesc: "Long Description",
  };
  const indices = GetDataIndices(HEADINGS, roleDescriptionData);

  const roleInfos = reduce(
    drop(roleDescriptionData),
    (curr, rdd) => {
      const rowData = GetRowData(indices, rdd);
      curr[rowData.Role] = {
        name: rowData.Role,
        team: toLower(rowData.Team),
        isLead: rowData.IsLead === "TRUE",
        reportsTo: rowData.ReportsTo,
        shortDesc: rowData.ShortDesc,
        longDesc: rowData.LongDesc,
      };
      return curr;
    },
    {}
  );
  return roleInfos;
}

export { useRoleDescriptions };
