import React, { useState, useEffect } from "react";
import { getWeeksBetween } from "./time";
import { ResponsiveBullet } from "@nivo/bullet";
import styled from "styled-components";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useDarkMode } from "../../../util/darkmode";

const VerticalGraphProps = {
  spacing: 80,
  layout: "vertical",
  margin: {
    top: 10,
    right: 50,
    bottom: 60,
    left: 50,
  },
  titlePosition: "before",
  titleOffsetY: 460,
};

const HorizontalGraphProps = {
  spacing: 50,
  margin: {
    left: 60,
    bottom: 20,
    right: 5,
  },
  titleOffsetX: -60,
  titlePosition: "before",
};

function getCustomTooltip(data, category, usePercentages) {
  return ({ v0, v1, color }) => {
    let amount = v1 ? v1 : v0;
    let label = amount.toFixed(1);

    const budgetRef = data.budget[category] + data.contingency[category];
    const scopeRef = budgetRef;

    function isLabelEqual(amount, reference) {
      if (usePercentages) amount = (amount * 100) / reference;
      return label === amount.toFixed(1);
    }

    const unit = (nonPerc, perc = "%") => (usePercentages ? perc : ` ${nonPerc}`);

    function updateLabel() {
      //The main bar
      if (color === "var(--time-colour)") {
        //Budget
        if (isLabelEqual(data.spent[category], budgetRef)) {
          label = `${label}${unit("days")} so far`;
          return;
        }

        //Scope
        if (isLabelEqual(data.completed[category], scopeRef)) {
          label = `Completed: ${label}${unit("days of estimated work")}`;
          return;
        }

        //Time
        label = `${label}${unit("weeks")} so far (updated on ${data.time.lastUpdated.label})`;
        return;
      }

      //Bottom background area
      if (color === "var(--light)" || color === "var(--body-bg)") {
        //Budget
        if (isLabelEqual(data.budget[category], budgetRef)) {
          label = `Base budget: ${label}${unit("days")}`;
          return;
        }

        //Scope
        if (isLabelEqual(data.estimated[category], scopeRef)) {
          label = `${label}${unit("days")} of work has been estimated`;
          return;
        }

        //Time
        label = `Total: ${data.time.totalWeeks} weeks`;
        return;
      }

      //Budget Contingency
      if (color === "var(--warning)") {
        label = `Contingency: ${(v1 - v0).toFixed(1)}${unit("days")}`;
        return;
      }

      //Over budget
      if (color === "var(--danger)") {
        label = `Over-budget by: ${(v1 - v0).toFixed(1)}${unit("days")}`;
        return;
      }

      //First marker - full budget, or "Today"
      if (color === "var(--marker-colour)") {
        //Budget
        if (isLabelEqual(budgetRef, budgetRef)) {
          label = `Full budget: ${budgetRef.toFixed(1)} days`;
          return;
        }

        //Time
        label = `Today (${data.time.today.label})`;
        return;
      }

      //Projected Spend
      if (color === "var(--info)") {
        function isAboveTopOfBudget(amount) {
          if (usePercentages) {
            return amount > 100 && amount > (data.spent[category] * 100) / budgetRef;
          } else {
            return amount > budgetRef && amount > data.spent[category];
          }
        }

        //if it's above the top of the graph, it might not be accurate (and so needs to be re-calculated)
        if (isAboveTopOfBudget(amount)) {
          const timePerc = Math.min(
            1,
            getWeeksBetween(data.time.start, data.time.lastUpdated) / getWeeksBetween(data.time.start, data.time.end)
          );
          amount = data.spent[category] / timePerc;
          if (usePercentages) {
            amount *= 100 / budgetRef;
          }
        }

        label = `Projected Spend: ${amount.toFixed(1)}${unit("days")}`;
        if (!usePercentages) {
          const difference = (usePercentages ? 100 : budgetRef) - amount;
          if (difference >= 0) {
            label += ` (${difference.toFixed(1)}${usePercentages ? "%" : ""} under budget)`;
          } else {
            label += ` (${(-difference).toFixed(1)}${usePercentages ? "%" : ""} over budget)`;
          }
        }
        return;
      }

      //Untagged marker
      if (color === "var(--untagged-colour)") {
        label = `${label}${unit("days")} in this stage are untagged`;
        return;
      }

      //unassigned to stage marker
      if (color === "var(--unassigned-colour)") {
        //if there's no unassigned, just mirror the bar below
        if (isLabelEqual(data.spent[category], budgetRef)) {
          label = `${label}${unit("days")} so far`;
          return;
        }

        let unassigned = data.unassigned;
        if (usePercentages) unassigned *= 100 / budgetRef;
        label = `${unassigned.toFixed(1)}${unit("days")} unassigned to a stage (max stage spend: ${label}${unit(
          "days"
        )})`;
      }
    }

    updateLabel();

    if (color === "transparent") {
      return <></>;
    }
    return <StyledTooltip style={{ background: "var(--body-bg)" }}>{label}</StyledTooltip>;
  };
}

const StyledTooltip = styled.div`
  background: var(--body-bg);
  color: var(--project-graph-colour);
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--project-colour);
  font-size: 0.8em;
`;

function BulletGraph({ data, category, isVertical, usePercentages }) {
  const [builtData, setBuiltData] = useState();
  const GraphProps = isVertical ? VerticalGraphProps : HorizontalGraphProps;
  const StyledText = isVertical ? StyledVerticalText : StyledHorizontalText;
  const [isDark] = useDarkMode();

  useEffect(() => {
    if (!data.time.start || isNaN(data.time.start.day)) {
      return;
    }

    function buildData(data, category) {
      var ret = [];
      const timePerc = Math.min(
        1,
        getWeeksBetween(data.time.start, data.time.lastUpdated) / getWeeksBetween(data.time.start, data.time.end)
      );

      const maxProjectedPercentage = 100;
      const scopeData = {
        time: data.time,
        budget: data.budget[category] || 0,
        spent: data.spent[category] || 0,
        estimated: data.estimated[category] || 0,
        completed: data.completed[category] || 0,
        contingency: data.contingency[category] || 0,
        untagged: category === "Total" ? data.untagged : 0,
        unassigned: category === "Total" ? data.unassigned : 0,
        displayedProjectedSpend: Math.min(
          timePerc > 0 ? data.spent[category] / timePerc : 0,
          maxProjectedPercentage *
            Math.max(data.spent[category] || 0, (data.budget[category] || 0) + (data.contingency[category] || 0))
        ),
      };
      if (data.time && data.time.start) {
        ret.push(buildTimeData(scopeData));
        const bdata = buildBudgetData(scopeData);
        if (bdata) ret.push(bdata);
        const sdata = buildScopeData(scopeData);
        if (sdata) ret.push(sdata);
      }
      return ret;
    }

    function buildTimeData(data) {
      const endWeeks = data.time.totalWeeks;
      const thisWeekDays = data.time.activeNow ? data.time.today.day / 5 : 0;
      const currentWeeks = data.time.weeksSoFar + thisWeekDays;
      let lastUpdatedWeeks = getWeeksBetween(data.time.start, data.time.lastUpdated);
      if (data.time.today.week === data.time.lastUpdated.week) {
        lastUpdatedWeeks = data.time.weeksSoFar + (data.time.activeNow ? data.time.lastUpdated.day / 5 : 0);
      }

      const progressWeeks = lastUpdatedWeeks >= endWeeks ? endWeeks : lastUpdatedWeeks;
      const budgetTotal = data.budget + data.contingency;
      let budgetSpendPerc = budgetTotal > 0 ? (data.spent + data.unassigned) / budgetTotal : 1;
      if (isNaN(budgetSpendPerc)) budgetSpendPerc = 1;
      let projectedProgress = data.displayedProjectedSpend / budgetTotal;
      if (isNaN(projectedProgress)) projectedProgress = 0;
      let estimated = data.estimated / budgetTotal;
      if (isNaN(estimated)) estimated = 0;
      let multiplier = Math.max(budgetSpendPerc, estimated, projectedProgress, 1);
      if (multiplier === Infinity) multiplier = 1;
      if (multiplier > 1.5) multiplier = 1;

      let factor = 1;
      if (usePercentages) {
        factor = 100 / endWeeks;
      }

      return {
        id: "Time",
        ranges: [factor * endWeeks, factor * endWeeks, factor * endWeeks, factor * endWeeks * multiplier],
        measures: [factor * progressWeeks],
        markers: currentWeeks < endWeeks ? [factor * currentWeeks] : [],
        title: (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-budget-graph`}>Time before delivery, and how much we've used</Tooltip>}
          >
            <StyledText>
              <text>Time</text>
              <Subtitle transform="translate(0,20)">({usePercentages ? "%" : "Weeks"})</Subtitle>
            </StyledText>
          </OverlayTrigger>
        ),
      };
    }

    function buildScopeData(data) {
      const progress = data.spent;
      const budget = data.budget;
      if (budget === 0 && progress === 0) return undefined;
      const contingency = data.contingency;
      const projectedProgress = data.displayedProjectedSpend;
      const completed = data.completed;
      const estimated = data.estimated;
      if (estimated === 0) return undefined;

      let factor = 1;
      if (usePercentages) {
        factor = 100 / (budget + contingency);
      }

      return {
        id: "Scope",
        ranges: [
          factor * estimated || 0.0000001,
          factor * estimated || 0.0000001,
          factor * estimated || 0.0000001,
          factor *
            Math.max(estimated, projectedProgress, progress + data.unassigned, budget + contingency, data.untagged),
        ],
        measures: [factor * completed || 0.0000001],
        maxValue: factor * Math.max(budget + contingency, estimated),
        title: (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-budget-graph`}>Days estimated (and completed) in Jira</Tooltip>}
          >
            <StyledText>
              <text>Scope</text>
              <Subtitle transform="translate(0,20)">({usePercentages ? "%" : "Days"})</Subtitle>
            </StyledText>
          </OverlayTrigger>
        ),
      };
    }

    function buildBudgetData(data) {
      const progress = data.spent;
      const budget = data.budget;
      if (budget === 0 && progress === 0) return undefined;
      const contingency = data.contingency;
      const projectedProgress = data.displayedProjectedSpend;
      const estimated = data.estimated;

      let factor = 1;
      if (usePercentages) {
        factor = 100 / (budget + contingency || 0.0000001);
      }

      return {
        id: "Budget",
        ranges: [
          factor * budget || 0.0000001,
          factor * (budget + contingency) || 0.0000001,
          factor * Math.max(progress, budget + contingency),
          factor *
            Math.max(estimated, projectedProgress, progress + data.unassigned, budget + contingency, data.untagged),
        ],
        measures: [factor * progress],
        maxValue: factor * Math.max(progress + data.unassigned, budget + contingency),
        markers: [
          factor * (budget + contingency),
          factor * projectedProgress,
          ...(data.unassigned > 0 ? [factor * (progress + data.unassigned)] : [factor * progress]),
          ...(data.untagged > 0 ? [factor * data.untagged] : []),
        ],
        title: (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={`tooltip-budget-graph`}>Days budgeted, and logged in toggl</Tooltip>}
          >
            <StyledText>
              <text>Budget</text>
              <Subtitle transform="translate(0,20)">({usePercentages ? "%" : "Days"})</Subtitle>
            </StyledText>
          </OverlayTrigger>
        ),
      };
    }

    setBuiltData(buildData(data, category));
    // eslint-disable-next-line
  }, [JSON.stringify(data), category, usePercentages]);

  if (!builtData) {
    return <div></div>;
  }

  return (
    <Container isVertical={isVertical}>
      <ResponsiveBullet
        data={builtData}
        theme={{
          textColor: "var(--project-text-colour)",
          axis: {
            ticks: {
              line: {
                stroke: "var(--project-text-colour)",
              },
            },
          },
        }}
        rangeColors={[isDark ? "var(--body-bg)" : "var(--light)", "var(--warning)", "var(--danger)", "transparent"]}
        rangeBorderColor={({ color }) => {
          if (color === "transparent") return color;
          return "var(--project-graph-colour)";
        }}
        rangeBorderWidth={1}
        measureColors={["var(--time-colour)"]}
        markerColors={["var(--marker-colour)", "var(--info)", "var(--unassigned-colour)", "var(--untagged-colour)"]}
        tooltip={getCustomTooltip(data, category, usePercentages)}
        {...GraphProps}
      />
    </Container>
  );
}

const Container = styled.div`
  height: ${({ isVertical }) => (isVertical ? "500px" : "250px")};
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const Subtitle = styled.text`
  font-size: 0.8em;
`;

const StyledVerticalText = styled.g`
  fill: var(--project-text-colour);
  text-anchor: middle;
  font-size: 1.5em;
`;

const StyledHorizontalText = styled.g`
  fill: var(--project-text-colour);
  text-anchor: start;
  font-size: 1em;
`;

export default BulletGraph;
