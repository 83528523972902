import { useDispatch, useSelector } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";

export const SET_DARKMODE = `darkmode/set`;

const STORED_DARKMODE = `darkmode/isOn`;

const initialState = {};

const setDarkMode = (isOn) => {
  reactLocalStorage.set(STORED_DARKMODE, isOn);
  return {
    type: SET_DARKMODE,
    isOn,
  };
};

function useDarkMode() {
  const dispatch = useDispatch();
  let isCurrentlyOn = useSelector((state) => state.darkmode.isOn);
  if (isCurrentlyOn === undefined) {
    isCurrentlyOn = reactLocalStorage.get(STORED_DARKMODE, false) === "true";
    dispatch(setDarkMode(isCurrentlyOn));
  }
  return [isCurrentlyOn, (isOn) => dispatch(setDarkMode(isOn))];
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_DARKMODE:
      return {
        ...state,
        isOn: action.isOn,
      };
    default:
      return state;
  }
}

export { useDarkMode };
