import React, { Fragment } from "react";
import { has, slice } from "lodash";
import useLinks from "hooks/links";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import styled from "styled-components";

function string_to_slug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

function ProjectLinks({ project }) {
  const [links] = useLinks();
  if (!has(links, project.code)) {
    return <Fragment></Fragment>;
  }

  const topLinks = slice(links[project.code], 0, 3);
  const restLinks = links[project.code];

  return (
    <Fragment>
      {topLinks.map((link, index) => (
        <OverlayTrigger
          key={index}
          placement="bottom"
          overlay={<Tooltip id={`tooltip-${string_to_slug(link.label)}`}>{link.label}</Tooltip>}
        >
          <StyledLink href={link.url} key={link.url} className="mx-2" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className="mr-2" icon={link.icon} />
          </StyledLink>
        </OverlayTrigger>
      ))}
      {restLinks.length > 0 && (
        <Dropdown alignRight>
          <StyledToggle variant="link">
            <FontAwesomeIcon icon={faLink} />
          </StyledToggle>
          <Dropdown.Menu>
            {restLinks.map((link, index) => (
              <Dropdown.Item key={link.url || index} href={link.url} target="_blank">
                {link.icon && <FontAwesomeIcon className="mr-2" icon={link.icon} />}
                {link.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Fragment>
  );
}

const StyledToggle = styled(Dropdown.Toggle)`
  color: var(--card-text-color) !important;
  margin-right: 0.5em;

  &:hover svg {
    filter: drop-shadow(2px 2px 0 var(--card-overlay));
  }
`;

const StyledLink = styled.a`
  color: var(--card-text-color) !important;

  &:hover svg {
    filter: drop-shadow(2px 2px 0 var(--card-overlay));
  }
`;

export default ProjectLinks;
