import { split } from "lodash";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Route, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { useRoleDescriptions } from "../hooks/roleDescriptions";
import { slugs } from "../util/slugs";
import { RoleImage } from "./roles/roleImages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { GitbookFrame } from "./gitbook-frame";

const RoleModalRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Route path={`${path}${path.endsWith("/") ? "" : "/"}${slugs.role}/${slugs.roleSlug}`} component={RoleModal} />
  );
};

const RoleModal = () => {
  const { roleSlug } = useParams();
  const roleDescriptions = useRoleDescriptions();
  const role = roleDescriptions[roleSlug.replace("-", " ")];
  const location = useLocation();
  const history = useHistory();
  const [isShown, setShown] = useState(true);

  if (!role) return <></>;

  const hide = () => {
    history.push({
      ...location,
      pathname: split(location.pathname, `/${slugs.role}/`)[0] + "/",
    });
    setShown(false);
  };

  return (
    <StyledModal size="lg" centered show={isShown} onHide={hide}>
      <Modal.Header>
        <div>
          <a href={role.longDesc} target="_blank" rel="noopener noreferrer">
            {role.name}
            <FontAwesomeIcon className="ml-2" icon={faExternalLinkAlt} size="sm" />
          </a>
        </div>
        <RoleImage roleInfo={role} size="xs" />
      </Modal.Header>
      <StyledModalBody className="p-0">
        <GitbookFrame roleName={roleSlug.replace("-", " ")} />
      </StyledModalBody>
      <Modal.Footer>
        <Button onClick={hide}>Close</Button>
      </Modal.Footer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 700px;
  }
`;

const StyledModalBody = styled(Modal.Body)`
  height: 60vh;
`;

export { RoleModalRoutes };
