import { faChevronLeft, faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Container, ListGroup, Row } from "react-bootstrap";
import useProjects from "hooks/projects";
import { slugs } from "../util/slugs";

const PersonWeek = ({ person }) => {
  const [currentWeek, setCurrentWeek] = useState(0);
  const [projects] = useProjects();

  const today = new Date();
  const formattedToday = `${today.getDate()}/${today.getMonth() + 1}`;

  return (
    <Container>
      <Row style={{ alignItems: "center", justifyContent: "center" }}>
        <Button variant="link" disabled={currentWeek === 0} onClick={() => setCurrentWeek(currentWeek - 1)}>
          <FontAwesomeIcon className="mx-2" icon={faChevronLeft} />
        </Button>
        <ListGroup horizontal>
          {person.weeks[currentWeek].map((day) => (
            <ListGroup.Item active={day.date === formattedToday} key={day.date} className="text-center py-1">
              {day.date}
              <br />
              {projects.find((p) => p.code === day.project) ? (
                <a
                  href={`/${slugs.project}/${day.project}`}
                  className={day.date === formattedToday ? "text-light" : ""}
                >
                  {day.project}
                </a>
              ) : (
                <span>{day.project}</span>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Button
          variant="link"
          disabled={currentWeek === person.weeks.length - 1}
          onClick={() => setCurrentWeek(currentWeek + 1)}
        >
          <FontAwesomeIcon className="mx-2" icon={faChevronRight} />
        </Button>
      </Row>
      <Row style={{ justifyContent: "center" }}>
        {person.weeks.map((week, index) => (
          <Button
            key={index}
            variant="link"
            size="sm"
            className="p-1"
            style={{ fontSize: "0.5em" }}
            disabled={currentWeek === index}
            onClick={() => setCurrentWeek(index)}
          >
            <FontAwesomeIcon icon={faCircle} />
          </Button>
        ))}
      </Row>
    </Container>
  );
};

export { PersonWeek };
