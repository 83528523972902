import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import auth from "./util/auth";
import gsheets from "./util/gsheets";
import darkmode from "./util/darkmode";

import { combineReducers, install } from "redux-loop";

const store = createStore(
  combineReducers({
    auth,
    gsheets,
    darkmode,
  }),
  {},
  compose(install(), applyMiddleware(thunk))
);

export default store;
