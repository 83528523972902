import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

function Aspected({ ratio, children, style, className }) {
  return (
    <Container style={{ ...style, paddingTop: ratio }} className={className}>
      <Inner>{children}</Inner>
    </Container>
  );
}

export default Aspected;
