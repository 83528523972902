import useGSheet from "./gsheets";
import { map, drop, head } from "lodash";

const spreadsheetId = "1Uk6Q74QhwR-65n7IO3qFVaZxUlCMCqfvFO5_vWhgpXQ";
const sheet = "CC People";

function usePeople() {
  const [peoplePropertiessData, refreshPeopleProperties] = useGSheet(spreadsheetId, sheet);

  const headers = head(peoplePropertiessData);
  const people = map(drop(peoplePropertiessData), (d) => ({
    name: d[0],
    emailPrefix: d[1],
    weeks: [
      d.slice(2, 7).map((p, i) => ({ project: p, date: headers[2 + i] })),
      d.slice(7, 12).map((p, i) => ({ project: p, date: headers[7 + i] })),
      d.slice(12, 17).map((p, i) => ({ project: p, date: headers[12 + i] })),
      d.slice(17, 22).map((p, i) => ({ project: p, date: headers[17 + i] })),
    ],
  }));

  return [people, refreshPeopleProperties];
}

export default usePeople;
