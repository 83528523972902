import React, { Fragment } from "react";
import { has, isEmpty } from "lodash";
import useLinks from "hooks/links";
import Nav from "react-bootstrap/Nav";
import styled from "styled-components";
import { useUser, logout } from "util/auth";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { ThemeSwitcher } from "./themeSwitch";
import { slugs } from "../util/slugs";

function GlobalLinks({ person }) {
  const [links] = useLinks();
  const { projectCode, tabName } = useParams();
  const { user } = useUser();
  const gsheets = useSelector((state) => state.gsheets);
  const history = useHistory();
  const location = useLocation();

  const goToPersonPage = () => {
    history.push({
      ...location,
      pathname: `/${slugs.person}/${person.name.replace(" ", "-")}`,
    });
  };

  if (!has(links, "CORE")) {
    return <Fragment></Fragment>;
  }

  const globalLinks = links["CORE"];
  let homeLink = "/";
  if (projectCode && tabName) {
    homeLink = `/?${projectCode}=${tabName}`;
  }

  return (
    <Navbar expand="lg" collapseOnSelect>
      <Navbar.Brand>
        <Link to={homeLink}>CTG Dashboards</Link>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav>
          {globalLinks.map((link) => (
            <NavLink size="sm" key={link.url} href={link.url} target="_blank" rel="noopener noreferrer">
              {link.label}
            </NavLink>
          ))}
        </Nav>
        {user && (
          <Nav className="ml-auto">
            {(!gsheets?.initialised || !isEmpty(gsheets.requestsInProgress)) && (
              <Nav.Item style={{ opacity: "30%" }} className="mr-2 mt-1">
                <Spinner animation="border" size="sm" className="mt-2 mr-1" />
                <small>Checking for updates...</small>
              </Nav.Item>
            )}
            <Nav.Item className="mx-2">
              <ThemeSwitcher />
            </Nav.Item>
            <NavDropdown alignRight title={user ? user.displayName : ""}>
              <NavDropdown.Item disabled={!person} onClick={goToPersonPage}>
                My Roles
              </NavDropdown.Item>
              <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}

const NavLink = styled(Nav.Link)`
  font-size: 0.75em;
`;

export default GlobalLinks;
