import { initializeApp } from "firebase/app";
import { getAuth, signOut, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useState, useEffect } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { useDispatch, useSelector } from "react-redux";
import { endsWith } from "lodash";

export const FINISH_LOADING = `auth/loading/finish`;
export const SET_AUTHORISED = `auth/set`;

const firebaseConfig = {
  apiKey: "AIzaSyDo-PRQZX1ZOAVcyFbMjbXXhKaCSbs_wbI",
  authDomain: "ctg-d-7a77f.firebaseapp.com",
  projectId: "ctg-d-7a77f",
  storageBucket: "ctg-d-7a77f.appspot.com",
  messagingSenderId: "860220436847",
  appId: "1:860220436847:web:1653be54e5e4187d61f89a",
};

const initialState = {
  loaded: false,
  isAuthorised: false,
};

initializeApp(firebaseConfig);
const auth = getAuth();

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    return res.user;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

const useUser = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const authStatus = useSelector((state) => state.auth);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
      dispatch({
        type: FINISH_LOADING,
      });
      dispatch({
        type: SET_AUTHORISED,
        isAuthorised: user && endsWith(user.email, "@chaostheorygames.com"),
      });
      if (user) reactLocalStorage.setObject("email", user?.email);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!authStatus.loaded) {
      dispatch({
        type: SET_AUTHORISED,
        isAuthorised: reactLocalStorage.getObject("authorised"),
      });
    }
  }, [authStatus.loaded, dispatch]);
  return { user, ...authStatus };
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FINISH_LOADING:
      return {
        ...state,
        loaded: true,
      };
    case SET_AUTHORISED:
      if (state.loaded) {
        reactLocalStorage.setObject("authorised", action.isAuthorised);
      }
      return {
        ...state,
        isAuthorised: action.isAuthorised,
      };
    default:
      return state;
  }
}

export { useUser, signInWithGoogle, logout };
