import { isEqual, isEqualWith } from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadGet } from "../util/gsheets";

function useGSheet(spreadsheetId, sheet, range = "A:ZZ") {
  const gsheets = useSelector((state) => state.gsheets);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LoadGet(spreadsheetId, sheet, range).loadData());
  }, [dispatch, spreadsheetId, sheet, range]);

  const doRefresh = () => dispatch(LoadGet(spreadsheetId, sheet, range).loadData(true));

  const [sheetData, setSheetData] = useState({});
  useEffect(() => {
    const newData = LoadGet(spreadsheetId, sheet, range).getData({ gsheets });
    if (!isEqual(newData, sheetData)) {
      setSheetData(newData);
    }
    // eslint-disable-next-line
  }, [spreadsheetId, sheet, range, gsheets.data[spreadsheetId], gsheets.localData[spreadsheetId]]);

  return [sheetData, doRefresh];
}

export function useGSheets(sheetSpecifications) {
  const gsheets = useSelector((state) => state.gsheets);
  const dispatch = useDispatch();
  useEffect(() => {
    sheetSpecifications.forEach(({ spreadsheetId, sheet, range }) => {
      dispatch(LoadGet(spreadsheetId, sheet, range).loadData());
    });
  }, [dispatch, sheetSpecifications]);

  const doRefresh = () => {
    sheetSpecifications.forEach(({ spreadsheetId, sheet, range }) => {
      dispatch(LoadGet(spreadsheetId, sheet, range).loadData(true));
    });
  };

  const [sheetData, setSheetData] = useState([]);
  useEffect(() => {
    const newData = sheetSpecifications.map(({ spreadsheetId, sheet, range }) =>
      LoadGet(spreadsheetId, sheet, range).getData({ gsheets })
    );
    if (!isEqualWith(newData, sheetData)) setSheetData(newData);
    // eslint-disable-next-line
  }, [sheetSpecifications, gsheets.data, gsheets.localData]);

  return [sheetData, doRefresh];
}

export function sheetSpecs(spreadsheetId, sheets) {
  return sheets.map((sheet) => ({
    spreadsheetId: spreadsheetId,
    sheet: sheet,
    range: "A:ZZ",
  }));
}

export default useGSheet;
