import React from "react";
import { Container } from "react-bootstrap";
import ProjectCard from "components/project";
import GlobalLinks from "components/global-links";
import { useParams, useHistory } from "react-router-dom";
import useProjects from "hooks/projects";
import { filter, isEmpty, sortBy, toNumber } from "lodash";
import { slugs } from "../util/slugs";
import { RoleModalRoutes } from "../components/roleModal";
import { PersonModalRoutes } from "../components/personModal";

function ProjectPage() {
  const [projects] = useProjects();
  const history = useHistory();
  const { projectCode } = useParams();
  const [projectSlug, milestoneString] = projectCode.split("-");
  let projectMilestones = filter(projects, { code: projectSlug });
  if (projectMilestones.length === 0) {
    return <></>;
  }

  const milestoneNumber = isEmpty(milestoneString) ? 0 : toNumber(milestoneString);
  projectMilestones = sortBy(
    projectMilestones,
    (milestone) =>
      (milestone.milestone === milestoneNumber ? -1000 : 0) +
      (milestone.status === "Active" ? -100 : 0) -
      milestone.milestone
  );
  const project = projectMilestones[0];

  return (
    <Container fluid>
      <GlobalLinks />
      <Container>
        <ProjectCard
          project={project}
          onTabSelected={(tab) =>
            history.push(`/${slugs.project}/${project.code}-${project.milestone}${tab ? `/${slugs.tab}/${tab}` : ""}`)
          }
        />
      </Container>
      <RoleModalRoutes />
      <PersonModalRoutes />
    </Container>
  );
}

export default ProjectPage;
