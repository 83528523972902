const slugs = {
  project: "project",
  projectCode: ":projectCode",
  tab: "tab",
  tabName: ":tabName",
  person: "person",
  personSlug: ":personSlug",
  role: "role",
  roleSlug: ":roleSlug",
};

export { slugs };
