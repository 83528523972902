import React from "react";
import {map, drop} from 'lodash';
import Figure from 'react-bootstrap/Figure';

function ImageRenderer({data, className}) {
  return (
    <Figure className={className}>
      <Figure.Image src={data.img} fluid />
      <Figure.Caption className="text-center">
        <a href={data.link} target="_blank" rel="noopener noreferrer">
          {data.text}
        </a>
      </Figure.Caption>
    </Figure>

  )
}

function buildImageData(tabData) {
  return map(drop(tabData), d => ({
    project: d[0],
    img: d[1],
    text: d[2],
    link: d[3]
  }));
}

const imageTab = {
  id: 'image',
  buildData: buildImageData,
  component: ImageRenderer
}

export default imageTab;
