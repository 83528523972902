import React, { useRef } from "react";
import styled from "styled-components";
import { useRoleDescriptions } from "../hooks/roleDescriptions";
import { useDarkMode } from "../util/darkmode";

const GitbookFrame = ({ roleName }) => {
  const [isDark] = useDarkMode();
  const iframeRef = useRef();
  const roleDescriptions = useRoleDescriptions();
  const role = roleDescriptions[roleName];

  if (!role) return <></>;

  return <StyledIframe isDarkMode={isDark} ref={iframeRef} name="gitbook" src={role.longDesc} />;
};

const StyledIframe = styled.iframe`
  width: min(700px, 100%);
  height: 100%;
  border: 0;
  margin: 0 auto;
  ${({ isDarkMode }) => (isDarkMode ? "filter: invert(1);" : "")}
`;

export { GitbookFrame };
