import useGSheet, { useGSheets, sheetSpecs } from "./gsheets";
import { map, zip, drop, isNil, find } from "lodash";
import tabTypes from "hooks/tabs";
import { useState } from "react";
import { useEffect } from "react";

const spreadsheetId = "1Uk6Q74QhwR-65n7IO3qFVaZxUlCMCqfvFO5_vWhgpXQ";
const sheet = "Tabs";

function useTabProperties() {
  const [tabPropertiessData, refreshTabProperties] = useGSheet(spreadsheetId, sheet);

  const [tabProperties, setTabProperties] = useState([]);
  useEffect(() => {
    setTabProperties(
      map(drop(tabPropertiessData), (d) => ({
        isActive: d[0] === "TRUE",
        name: d[1],
        sheet: d[2],
        type: d[3],
      })).filter((t) => t.isActive)
    );
  }, [tabPropertiessData]);

  return [tabProperties, refreshTabProperties];
}

function useTab(tabName) {
  const [tabPropertiessData] = useGSheet(spreadsheetId, sheet);
  const tabProperties = find(tabPropertiessData, (props) => props[1] === tabName);
  const [tabData, setTabData] = useState([]);

  const [tabDatas, refreshTab] = useGSheets(
    tabProperties
      ? [
          {
            spreadsheetId,
            sheet: tabProperties[2],
          },
        ]
      : []
  );

  useEffect(() => {
    if (tabDatas.length === 0) return;

    const tabType = tabProperties[3];
    if (!tabTypes[tabType]) return;
    setTabData(tabTypes[tabType].buildData(tabDatas[0]));
  }, [tabDatas, tabProperties]);

  return [tabData, refreshTab];
}

function useProjectTabs(project) {
  const [tabPropertiessData, refreshTabProperties] = useGSheet(spreadsheetId, sheet);
  const [tabProperties, setTabProperties] = useState([]);
  const [sheetSpecifications, setSheetSpecs] = useState([]);
  const [tabObjects, setTabObjects] = useState([]);

  useEffect(() => {
    const props = map(drop(tabPropertiessData), (d) => ({
      isActive: d[0] === "TRUE",
      name: d[1],
      sheet: d[2],
      type: d[3],
    })).filter((t) => t.isActive);
    setTabProperties(props);
    setSheetSpecs(
      sheetSpecs(
        spreadsheetId,
        props.map((tabProp) => tabProp.sheet)
      )
    );
  }, [tabPropertiessData]);

  const [tabDatas, refreshTabs] = useGSheets(sheetSpecifications);

  useEffect(() => {
    if (isNil(project)) return;

    const zippedTabData = zip(tabProperties, tabDatas).map((zt) => ({
      data: zt[1],
      ...zt[0],
    }));

    function getProjectDataForTab(tab, project) {
      if (!tabTypes[tab.type]) return null;
      const tabObjects = tabTypes[tab.type].buildData(tab.data);

      return tabObjects.find((d) => d.project === project.code || d.project === `${project.code}-${project.milestone}`);
    }

    setTabObjects(
      zippedTabData
        .filter((td) => td?.data)
        .map((td) => ({
          ...td,
          data: getProjectDataForTab(td, project),
        }))
        .filter((to) => to?.data)
    );
  }, [project, tabProperties, tabDatas]);

  const refreshAll = () => {
    refreshTabProperties();
    refreshTabs();
  };

  return [tabObjects, refreshAll];
}

export { useTab, useProjectTabs, useTabProperties };
