import _, { isEqualWith } from "lodash";
import { reactLocalStorage } from "reactjs-localstorage";

export const START_INITIALISING = `gsheets/initialise/start`;
export const FINISH_INITIALISING = `gsheets/initialise/finish`;
export const ADD_REQUEST = `gsheets/requests/add`;
export const RUN_REQUESTS = `gsheets/requests/run`;
export const SAVE_DATA = `gsheets/data/save`;

const config = {
  apiKey: "AIzaSyCGYp5xHp68Vl6rCfSrwRdmPA27oJDgVmk",
  discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
};

const initialState = {
  initialising: false,
  initialised: false,
  requestQueue: {},
  requestsInProgress: {},
  data: {},
  localData: {},
};

function initialiseAPI(dispatch) {
  dispatch({ type: START_INITIALISING });
  return new Promise((resolve) => {
    window.gapi.load("client", resolve);
  })
    .then(
      () =>
        new Promise((resolve) =>
          window.gapi.client
            .init({
              apiKey: config.apiKey,
              // Your API key will be automatically added to the Discovery Document URLs.
              discoveryDocs: config.discoveryDocs,
            })
            .then(resolve)
        )
    )
    .then(() => new Promise((resolve) => window.gapi.client.load("sheets", "v4").then(resolve)))
    .then(() => {
      dispatch({ type: FINISH_INITIALISING });
      return;
    });
}

function id({ spreadsheetId, sheet, range }) {
  return `${spreadsheetId}-${sheet}-${range}`;
}

export function LoadGet(spreadsheetId, sheet, range = "A:ZZ") {
  return {
    loadData: (force = false) => loadData(spreadsheetId, sheet, range, force),
    getData: (props) => getData(props, spreadsheetId, sheet, range),
  };
}

function getData(props, spreadsheetId, sheet, range = "A:ZZ") {
  if (props?.gsheets) {
    let myId = id({ spreadsheetId, sheet, range });
    if (props.gsheets.data && _.has(props.gsheets.data, myId)) return props.gsheets.data[myId];
    else {
      if (!_.has(props.gsheets.localData, myId)) {
        props.gsheets.localData[myId] = reactLocalStorage.getObject(myId, {});
      }
      return props.gsheets.localData[myId];
    }
  }
  return null;
}

function loadData(spreadsheetId, sheet, range = "A:ZZ", force = false) {
  return async (dispatch, getState) => {
    var state = getState();
    const requestId = id({ spreadsheetId, sheet, range });
    //if data already exists
    if (state.gsheets?.data && _.isArray(state.gsheets.data[requestId])) {
      if (!force) return;
      state.gsheets.data[requestId] = undefined;
    }
    return new Promise(function (resolve, reject) {
      //first initialise the API
      var state = getState();
      if (state.gsheets.requestQueue[requestId] || state.gsheets.requestsInProgress[requestId]) {
        return;
      }
      //add the request to the queue
      dispatch({ type: ADD_REQUEST, spreadsheetId, sheet, range });
      if (!state.gsheets?.initialised) {
        if (!state.gsheets?.initialising) {
          initialiseAPI(dispatch).then(resolve);
        }
        return;
      }
      resolve();
    }).then(() => {
      //run all the requests
      var state = getState();
      var requests = _.cloneDeep(state.gsheets.requestQueue);
      dispatch({ type: RUN_REQUESTS, ids: _.keys(requests) });
      _.forEach(requests, ({ spreadsheetId, sheet, range }, id) => {
        return window.gapi.client.sheets.spreadsheets.values
          .get({
            spreadsheetId,
            range: `${sheet}!${range}`,
          })
          .then((response) => {
            dispatch({ type: SAVE_DATA, id, data: response.result.values });
          });
      });
    });
  };
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case START_INITIALISING:
      return {
        ...state,
        initialised: false,
        initialising: true,
      };
    case FINISH_INITIALISING:
      return {
        ...state,
        initialised: true,
        initialising: false,
      };
    case ADD_REQUEST:
      if (_.has(state.requestQueue, id(action)) || _.has(state.requestsInProgress, id(action))) {
        return state;
      }
      var requestQueue = { ...state.requestQueue };
      requestQueue[id(action)] = _.pick(action, ["spreadsheetId", "sheet", "range"]);
      return {
        ...state,
        requestQueue,
      };
    case RUN_REQUESTS:
      return {
        ...state,
        requestQueue: _.omit(state.requestQueue, action.ids),
        requestsInProgress: {
          ...state.requestsInProgress,
          ..._.pick(state.requestQueue, action.ids),
        },
      };
    case SAVE_DATA:
      if (isEqualWith(action.data, state.data[action.id])) {
        return {
          ...state,
          requestsInProgress: _.omit(state.requestsInProgress, [action.id]),
        };
      }

      var data = _.cloneDeep(state.data);
      data[action.id] = action.data;
      reactLocalStorage.setObject(action.id, action.data);
      return {
        ...state,
        requestsInProgress: _.omit(state.requestsInProgress, [action.id]),
        data,
      };
    default:
      return state;
  }
}
