import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { useDarkMode } from "../util/darkmode";

function ThemeSwitcher() {
  const [isDarkMode, setDarkMode] = useDarkMode();

  const switchTheme = (e) => {
    setDarkMode(!isDarkMode);
  };

  useEffect(() => {
    isDarkMode
      ? document.documentElement.setAttribute("darkMode", "")
      : document.documentElement.removeAttribute("darkMode", "");
  }, [isDarkMode]);

  return (
    <Container onClick={switchTheme}>
      <Form.Switch inline checked={isDarkMode} onChange={switchTheme} label="🌙" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  font-size: 0.9em;
  align-items: center;
  height: 100%;
`;

export { ThemeSwitcher };
