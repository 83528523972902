import React from "react";
import "styles/global.scss";
import IndexPage from "./index";
import ProjectPage from "./project";
import { Switch, Route, Router } from "util/router.js";
import "util/analytics.js";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { signInWithGoogle, useUser } from "util/auth";
import { slugs } from "../util/slugs";
import { PersonImagePreloader } from "../components/roles/roleImages";

function App(props) {
  const { loaded, isAuthorised } = useUser();

  if (!loaded) {
    return <Spinner animation="border" />;
  }

  if (!isAuthorised) {
    return (
      <div className="text-center mt-4">
        Please log in with your CTG email to access the dashboards
        <div className="mt-4">
          <Button size="lg" onClick={signInWithGoogle}>
            Login
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Router>
        <Switch>
          <Route
            path={`/${slugs.project}/${slugs.projectCode}/${slugs.tab}/${slugs.tabName}`}
            component={ProjectPage}
          />
          <Route path={`/${slugs.project}/${slugs.projectCode}/`} component={ProjectPage} />

          <Route path="/" component={IndexPage} />
        </Switch>
      </Router>
      <PersonImagePreloader />
    </>
  );
}

export default App;
