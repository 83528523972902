import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import useProjects from "hooks/projects";
import ProjectColumn from "components/project-column";
import GlobalLinks from "components/global-links";
import { useUser } from "util/auth";
import usePeople from "hooks/people";
import { reactLocalStorage } from "reactjs-localstorage";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { filter, startsWith } from "lodash";
import qs from "qs";
import { useLocation } from "util/router";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { useTabProperties } from "hooks/useTabs";
import { useHistory } from "react-router-dom";
import { RoleModalRoutes } from "../components/roleModal";
import { PersonModalRoutes } from "../components/personModal";
import { PersonWeek } from "../components/person-week";

function IndexPage() {
  const [projects] = useProjects();
  const [people] = usePeople();
  const { user } = useUser();
  const [storedEmail] = useState(reactLocalStorage.getObject("email"));
  const [shownProjects, setShownProjects] = useState("Active");
  const { search } = useLocation();
  const [tabProperties] = useTabProperties();
  const [chosenTab, setChosenTab] = useState(search === "" ? -1 : undefined);
  const history = useHistory();
  const [visibleProjects, setVisibleProjects] = useState([]);

  useEffect(() => {
    setVisibleProjects(filter(projects, { status: shownProjects }));
  }, [shownProjects, projects]);

  const searchParams = search ? qs.parse(search.substr(1)) : {};

  const projectStatuses = ["Potential", "Active", "On Hold", "Complete"];

  const [person, setPerson] = useState();
  useEffect(() => {
    const email = user?.email || storedEmail;
    if (email && person === undefined) {
      setPerson(people.find((p) => startsWith(email, p.emailPrefix)));
    }
  }, [user, storedEmail, people, person]);

  function changeTabs(newTabIndex) {
    if (newTabIndex < 0) {
      history.push({
        search: "",
      });
    } else {
      for (let i = 0; i !== projects.length; ++i) {
        searchParams[`${projects[i].code}-${projects[i].milestone}`] = tabProperties[newTabIndex].sheet;
      }
      history.push({
        search: `?${qs.stringify(searchParams)}`,
      });
    }
    setChosenTab(newTabIndex);
  }

  if (!projects) {
    return <Spinner animation="border" />;
  }

  return (
    <Container fluid>
      <GlobalLinks person={person} />
      <Row className="mx-2" style={{ justifyContent: "space-between" }}>
        <div>
          <DropdownButton size="sm" title={`${shownProjects} Projects`}>
            {projectStatuses.map((status) => (
              <Dropdown.Item key={status} active={shownProjects === status} onClick={(e) => setShownProjects(status)}>
                {status}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <ToggleButtonGroup className="mt-1" size="sm" name="radio" value={chosenTab}>
            <ToggleButton variant="outline-primary" value={-1} onChange={() => changeTabs(-1)}>
              Summary
            </ToggleButton>
            {tabProperties
              .filter((t) => t.isActive)
              .map((tabProp, index) => (
                <ToggleButton
                  key={tabProp.sheet}
                  variant="outline-primary"
                  value={index}
                  onChange={() => changeTabs(index)}
                >
                  {tabProp.name}
                </ToggleButton>
              ))}
          </ToggleButtonGroup>
        </div>
        <div>{person && <PersonWeek person={person} />}</div>
        <div></div>
      </Row>
      <Row>
        {!projects && <Spinner animation="border" />}
        {visibleProjects.map((project) => (
          <ProjectColumn
            key={`${project.code}-${project.milestone}`}
            project={project}
            personName={person?.name}
            activeTab={searchParams[`${project.code}-${project.milestone}`]}
          />
        ))}
      </Row>
      <RoleModalRoutes />
      <PersonModalRoutes />
    </Container>
  );
}

export default IndexPage;
