import React from "react";
import tabTypes from "hooks/tabs";

function TabContent({ tab: { data, type }, project }) {
  const TabComponent = tabTypes[type].component;
  return <TabComponent className="mt-3" data={data} project={project} />;
}

export function TabSummaryContent({ tab: { data, type }, project }) {
  const TabComponent = tabTypes[type].summary;
  if (TabComponent) return <TabComponent className="mt-3" data={data} project={project} />;
  return <></>;
}

export default TabContent;
