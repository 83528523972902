import useGSheet from "./gsheets";
import { drop, has } from "lodash";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab, fas);

const spreadsheetId = "1Uk6Q74QhwR-65n7IO3qFVaZxUlCMCqfvFO5_vWhgpXQ";
const sheet = "Links";
const iconsSheet = "Icons";

function useLinks() {
  const [linksData, refresh] = useGSheet(spreadsheetId, sheet);
  const [iconsData] = useGSheet(spreadsheetId, iconsSheet);

  if (!linksData || !iconsData) return [linksData, refresh];

  const icons = drop(iconsData).reduce((acc, row) => {
    acc[row[0]] = [row[1], row[2]];
    return acc;
  }, {});

  let lastProject = "";
  const links = drop(linksData).reduce((acc, row) => {
    const [project, icon, label, url] = row;
    if (project !== "" || acc.length === 0) {
      acc[project] = [];
      lastProject = project;
    }
    acc[lastProject].push({
      icon: icon && has(icons, icon) ? icons[icon] : null,
      label,
      url,
    });
    return acc;
  }, {});

  return [links, refresh];
}

export default useLinks;
