import useGSheet from "./gsheets";
import { map, drop, toNumber, kebabCase } from "lodash";
import { useState } from "react";
import { useEffect } from "react";

const spreadsheetId = "1Uk6Q74QhwR-65n7IO3qFVaZxUlCMCqfvFO5_vWhgpXQ";
const sheet = "Projects";

function useProjects() {
  const [projectData, refresh] = useGSheet(spreadsheetId, sheet);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (projectData !== null) {
      setProjects(
        map(drop(projectData), (d) => ({
          code: d[0],
          name: d[1],
          colour: kebabCase(d[2]),
          milestone: toNumber(d[3]),
          status: d[4],
        }))
      );
    }
  }, [projectData]);

  if (!projectData) return [projectData, refresh];

  return [projects, refresh];
}

export default useProjects;
