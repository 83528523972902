import { head, indexOf, mapValues } from "lodash";

function GetDataIndices(headings, data) {
  return mapValues(headings, (headingString) => indexOf(head(data), headingString));
}

function GetRowData(indices, row) {
  return mapValues(indices, (index) => (index >= 0 ? row[index] : undefined));
}

export { GetDataIndices, GetRowData };
