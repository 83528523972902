import { has, map, reduce, split, values } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { Modal, Button, Row, Col, ListGroup, ListGroupItem, Badge, Container } from "react-bootstrap";
import { Route, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import usePeople from "../hooks/people";
import useProjects from "hooks/projects";
import { slugs } from "../util/slugs";
import { PersonWeek } from "./person-week";
import { useRoleDescriptions } from "../hooks/roleDescriptions";
import { useTab } from "../hooks/useTabs";
import { GitbookFrame } from "./gitbook-frame";
import { PersonImage, RoleImage } from "./roles/roleImages";

const PersonModalRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Route
      path={`${path}${path.endsWith("/") ? "" : "/"}${slugs.person}/${slugs.personSlug}`}
      component={PersonModal}
    />
  );
};

const PersonModal = () => {
  const location = useLocation();
  const history = useHistory();
  const [isShown, setShown] = useState(true);
  const [people] = usePeople();
  const [projects] = useProjects();
  const roleDescriptions = useRoleDescriptions();
  let { personSlug } = useParams();
  personSlug = personSlug.replace("-", " ");
  const [roles] = useTab("Roles");
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(0);
  const [personsRoles, setPersonsRoles] = useState([]);
  const [person, setPerson] = useState(null);

  useEffect(() => {
    if (people.length === 0) return;
    const foundPerson = people.find((person) => person.name === personSlug);
    if (foundPerson != null && (person == null || foundPerson.name !== person.name)) setPerson(foundPerson);
  }, [people, person, personSlug]);

  useEffect(() => {
    const allowedRoles = reduce(
      roles || [],
      (prev, roleProject) => {
        const project = projects.find((p) => p.code === roleProject.project);
        if (project == null || project.status !== "Active") return prev;

        const personProjectRole = roleProject.roles.find((rolePerson) => rolePerson.person === person.name);
        if (personProjectRole == null) return prev;

        for (const roleIndex in personProjectRole.roles) {
          const role = personProjectRole.roles[roleIndex];
          if (!has(prev, role)) {
            prev[role] = { name: role, projects: [] };
          }
          prev[role].projects.push({ code: roleProject.project, colour: project.colour });
        }

        return prev;
      },
      {}
    );
    if (values(allowedRoles).length === 0) return;
    setPersonsRoles(values(allowedRoles));
  }, [projects, roles, person]);

  const hide = () => {
    history.push({
      ...location,
      pathname: split(location.pathname, `/${slugs.person}/`)[0] + "/",
    });
    setShown(false);
  };

  return (
    <StyledModal size="lg" centered show={isShown} onHide={hide}>
      {person && (
        <Fragment>
          <Modal.Header>
            <Container fluid>
              <Row>
                <Col xs="3">
                  <PersonImage size="sm" person={person.name} />
                  <span className="ml-2">{person.name}</span>
                </Col>
                <Col xs="9">
                  <PersonWeek person={person} />
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <StyledModalBody className="py-0">
            <StyledRow>
              <Col xs="3" className="p-0">
                <ListGroup variant="flush">
                  {map(personsRoles, (role, index) => (
                    <ListGroupItem
                      action
                      onClick={() => setSelectedRoleIndex(index)}
                      active={index === selectedRoleIndex}
                      key={role.name}
                    >
                      <RoleImage plain size="xs" className="float-left" roleInfo={roleDescriptions[role.name]} />
                      <div>{role.name}</div>
                      <div className="d-flex flex-wrap" style={{ gap: "0.25em" }}>
                        {role.projects.map((project) => (
                          <Badge key={project.code} variant={project.colour}>
                            {project.code}
                          </Badge>
                        ))}
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
              <Col xs="9" className="p-0">
                {personsRoles && personsRoles.length > selectedRoleIndex && (
                  <GitbookFrame roleName={personsRoles[selectedRoleIndex].name} />
                )}
              </Col>
            </StyledRow>
          </StyledModalBody>
          <Modal.Footer>
            <Button onClick={hide}>Close</Button>
          </Modal.Footer>
        </Fragment>
      )}
    </StyledModal>
  );
};

const StyledRow = styled(Row)`
  flex-grow: 1;
`;

const StyledModal = styled(Modal)`
  padding-bottom: 0;
  .list-group-item-action:not(.active) {
    color: var(--body-color);
  }
`;

const StyledModalBody = styled(Modal.Body)`
  height: 60vh;
  display: flex;
  flex-direction: column;
`;

export { PersonModalRoutes };
