import React from "react";
import { map, drop } from "lodash";
import styled from "styled-components";
import Aspected from "components/aspected";

const IFrameComponent = styled.iframe`
  width: 100%;
  height: 100%;
  border: solid 1px #dee2e6;
  border-bottom: none;
  margin: 0 !important;
`;

function IFrameRenderer({ data, className }) {
  return (
    <Aspected ratio={"calc(80% + 9em)"}>
      <Aspected ratio={"calc(80% + 6em)"}>
        <IFrameComponent src={data.url} className={className} />
      </Aspected>
      <div className="text-center">
        <a href={data.link} target="_blank" rel="noopener noreferrer">
          {data.caption}
        </a>
      </div>
    </Aspected>
  );
}

function buildIFrameData(tabData) {
  return map(drop(tabData), (d) => ({
    project: d[0],
    url: d[1],
    caption: d[2],
    link: d[3],
  }));
}

const iframeTab = {
  id: "iframe",
  buildData: buildIFrameData,
  component: IFrameRenderer,
};

export default iframeTab;
