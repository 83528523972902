import React, { useState } from "react";
import { Card, Tabs, Tab, Spinner } from "react-bootstrap";
import { useProjectTabs } from "hooks/useTabs";
import TabContent, { TabSummaryContent } from "./tab-content";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt, faCompressAlt } from "@fortawesome/free-solid-svg-icons";
import { useParams, Link } from "react-router-dom";
import useProjects from "hooks/projects";
import { find, sortBy, toNumber } from "lodash";
import ProjectLinks from "./links";
import tabTypes from "hooks/tabs";
import { useEffect } from "react";
import { slugs } from "../util/slugs";
import { useDarkMode } from "../util/darkmode";

const ProjectImages = {
  CRAB: "CRAB.jpg",
  EEDU: "EEDU.png",
  HART: "HART.jpg",
  IPGO: "IPGO.jpg",
  FORC: "FORC.jpg",
  CFAD: "CFAD.jpg",
  GALA: "GALA.jpg",
};

function ProjectCard({ children, project, activeTab, onTabSelected }) {
  const [projects] = useProjects();
  const { projectCode, tabName } = useParams();
  const [projectSlug, milestone] = projectCode?.split("-") || [undefined, undefined];
  project = project || find(projects, { code: projectSlug, milestone: toNumber(milestone) });
  const [tabs] = useProjectTabs(project);
  const isHome = projectCode === undefined;
  const [isDarkMode] = useDarkMode();

  const bg = project.colour + (isDarkMode ? "-text" : "");
  const text = project.colour + (isDarkMode ? "" : "-text");
  const graph = project.colour + (isDarkMode ? "-dark-contrast" : "-contrast");

  activeTab = activeTab || tabName;

  const [linkUrl, setLinkUrl] = useState("/");

  useEffect(() => {
    let lUrl = `/${slugs.project}/`;
    if (isHome) {
      lUrl += `${project.code}-${project.milestone}`;
      if (activeTab) {
        lUrl += `/${slugs.tab}/${activeTab}`;
      }
    } else if (activeTab) {
      lUrl += `?${projectCode}=${activeTab}`;
    }
    if (lUrl !== linkUrl) {
      setLinkUrl(lUrl);
    }
  }, [isHome, project, activeTab, linkUrl, projectCode]);

  if (!project) {
    return <Spinner animation="border" />;
  }

  return (
    <StyledCard bg={bg} text={text} project={project.code} className="mb-5 mt-3">
      <Card.Header className="pb-0">
        <TopBar>
          <Card.Title>{project.name}</Card.Title>
          <TopRightBar>
            <ProjectLinks project={project} />
            <StyledLink to={linkUrl}>
              <FontAwesomeIcon icon={isHome ? faExpandAlt : faCompressAlt} />
            </StyledLink>
          </TopRightBar>
        </TopBar>
      </Card.Header>
      <StyledCardBody>
        <TabsParent colour={bg} text={text} graph={graph}>
          <StyledTabs
            activeKey={activeTab}
            onSelect={(key) => onTabSelected && onTabSelected(key === "summary" ? undefined : key)}
          >
            <Tab eventKey="summary" title={<TabTitle>Summary</TabTitle>}>
              {sortBy(tabs, (t) => tabTypes[t.type].summaryPriority).map((t) => (
                <TabSummaryContent key={t.sheet} tab={t} project={project} />
              ))}
            </Tab>
            {tabs.map((t) => (
              <Tab key={t.sheet} eventKey={t.sheet} title={<TabTitle>{t.name}</TabTitle>}>
                <TabContent tab={t} project={project} />
              </Tab>
            ))}
          </StyledTabs>
        </TabsParent>
      </StyledCardBody>
    </StyledCard>
  );
}

const TabTitle = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.5rem 1rem;
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
`;

const StyledLink = styled(Link)`
  color: var(--card-text-color) !important;

  &:hover svg {
    filter: drop-shadow(2px 2px 0 var(--card-overlay));
  }
`;

const StyledCard = styled(Card)`
  --card-bg-color: var(--${({ bg }) => bg});
  --card-text-color: var(--${({ text }) => text});
  ${({ project }) =>
    project &&
    ProjectImages[project] &&
    `
    background-image: url(/project-img/${ProjectImages[project]});
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    background-position: center top;
  `}
`;

const StyledCardBody = styled(Card.Body)`
  background-color: var(--card-overlay);
  backdrop-filter: blur(1px);
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
`;

const StyledTabs = styled(Tabs)`
  .nav-item.nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top: 0;
    padding: 0;
    margin-top: -1px;
  }

  .nav-item.nav-link.active {
    border-color: var(--project-colour);
    background-color: var(--project-colour) !important;
    color: var(--project-text-colour) !important;
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TopRightBar = styled.div`
  margin-top: -1em;
  display: flex;
  align-items: center;
`;

const TabsParent = styled.div`
  display: flex;
  flex-direction: column-reverse;

  --project-colour: var(--${(props) => props.colour});
  --project-text-colour: var(--${(props) => props.text});
  --project-graph-colour: var(--${(props) => props.graph}, var(--${(props) => props.text}));

  a,
  .btn-link {
    color: var(--project-text-colour) !important;
  }

  .nav {
    margin-bottom: -3.8rem;
    padding: 0 1rem;
    margin-top: -0.4rem;

    .nav-item.nav-link.active {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;

      ${TabTitle} {
        background-color: var(--card-overlay);
      }
    }
  }
`;

export default ProjectCard;
